global.tooltipHandler = {};

tooltipHandler.setTippyTooltips = function () {
  tippy('[data-tippy-content]', { allowHTML: true, touch: true });
};

tooltipHandler.hideTippy = function (selector) {
  var element = document.querySelector(selector);

  if (element && element._tippy) {
    element._tippy.hide();
  }
};

tooltipHandler.setEventListeners = function() {
  tooltipHandler.setTippyTooltips();

  tippy('[data-tippy-dropdown]', {
    content: function(target) {
      return $(target.dataset.tippyDropdown).html();
    },
    allowHTML: true,
    interactive: true,
    trigger: 'click',
    theme: 'dropdown',
    placement: 'bottom-start'
  });
};

setEventListeners(document, ["DOMContentLoaded", "turbo:render"], function(e) {
  tooltipHandler.setEventListeners();
});
