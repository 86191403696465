global.sidenav = {};

sidenav.open = function () {
  var popupOverlay = $('#popup_overlay');

  $("#sidenav").addClass("opened");
  $("body").addClass("tw-overflow-hidden");

  if (popupOverlay.length) {
    popupOverlay.attr("onclick", "sidenav.close();");
  } else {
    $("body").append('<div id="popup_overlay" class="popup_overlayBG" onclick="sidenav.close();"></div>');
  }
};

sidenav.close = function () {
  $("#sidenav").removeClass("opened");
  $('#popup_overlay').remove();
  $("#sidenav_content").empty();
  $("body").removeClass("tw-overflow-hidden");
};
